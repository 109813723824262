import React, { useEffect, useState } from 'react';
import AdminBar from '../../component/AdminBar';
import { Box, Typography, Pagination, Stack, Grid } from '@mui/material';
import { convertToObject, getData } from '../../functions';
import styles from '../../styles';
import { TimeGraph } from '../../component'


const Analytics = ({
  setProcedureInfo,
  doctorData,
  procedureInfo,
  facilityData,
  setFacilityData,
  userAnalyticsid,
  userAnalytics,
  setUserAnalytics,
}) => {
  /*
    May or may not use
  useEffect(() => {
    setPageMax(Math.ceil(filteredUserAnalytics.length / 6));
  }, [filteredUserAnalytics]);
  useEffect(() => {
    setProcPageMax(Math.ceil(filteredProcedureInfo.length / 6));
  }, [filteredProcedureInfo]);
  const style = styles;
  const [pageMax, setPageMax] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [procPageMax, setProcPageMax] = useState(0);
  const [procCurrentPage, setProcCurrentPage] = useState(1);
  const [paginatedArray, setPaginatedArray] = useState([]);
  const [procPaginatedArray, setProcPaginatedArray] = useState([]);
  */

  const [filteredUserAnalytics, setFilteredUserAnalytics] = useState([]);
  const [filteredProcedureInfo, setFilteredProcedureInfo] = useState([]);

 

  useEffect(() => {
    // Fetch data and set the procedure info
    getData('pricing', (data) => {
      //console.log('Fetched Procedure Data:', data); // Log fetched data for debugging
      setProcedureInfo(data);
    });
  }, [setProcedureInfo]);

  useEffect(() => {
    if (procedureInfo.length > 0 && facilityData.id) {
      if (typeof procedureInfo[0] !== 'object') {
        const temp = convertToObject(procedureInfo, [
          'id',
          'procedure_code',
          'procedure_or_surgery',
          'implant_cost',
          'user_id',
          'timestamp',
        ]);
        setProcedureInfo(temp);
      } else {
        const filteredProcedureData = procedureInfo.filter(
          (item) => item.user_id === facilityData.id
        );
        setFilteredProcedureInfo(filteredProcedureData);

      }
    }
  }, [procedureInfo, facilityData.id, setProcedureInfo]);

  useEffect(() => {
    if (userAnalytics.length > 0) {
      if (typeof userAnalytics[0] !== 'object') {
        const convertedData = convertToObject(userAnalytics, [
          'user_id',
          'time_logged_in',
          'time_logged_out',
          'id',
        ]);
        setUserAnalytics(convertedData);
      } else {
        //console.log(userAnalytics)
        const filteredUserAnalyticInfo = userAnalytics.filter(
          (item) => item.user_id === facilityData.id
        );
        setFilteredUserAnalytics(filteredUserAnalyticInfo);
       // console.log(filteredUserAnalyticInfo)
      }
    }
  }, [userAnalytics, facilityData.id, setUserAnalytics]);



 
  return (
    <Box>
      <AdminBar user={facilityData} userAnalyticsid={userAnalyticsid} />
      <Box sx={{ mt: '110px' }}>
        <Typography
          variant="h4"
          sx={{ color: 'white', display: 'flex', justifyContent: 'center' }}
        >
          Analytics

         
        </Typography>
        <Box  sx={{ color: 'white', display: 'flex', justifyContent: 'center' }}>
          <TimeGraph userAnalytics={userAnalytics}/>
        </Box>
      </Box>
    </Box>
  );
};

export default Analytics;
