import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { Chart } from "react-google-charts";

const TimeGraph = ({ userAnalytics }) => {
  const [timeData, setTimeData] = useState([["year", "logins"]]);
  const [monthData, setMonthData] = useState([["month", "logins"]]);
  useEffect(() => {
    if (userAnalytics.length > 0 &&  typeof userAnalytics[0] === 'object' ) {
      console.log(userAnalytics)
      // Step 1: Extract the years and count occurrences
      const yearCounts = userAnalytics.reduce((acc, entry) => {
        const year = entry.time_logged_in.slice(0, 4); // Extract year as string
        acc[year] = (acc[year] || 0) + 1; // Increment count for the year
        return acc;
      }, {});
      // Step 2: Convert yearCounts into an array format compatible with the chart
      const formattedData = Object.entries(yearCounts)
        .sort((a, b) => a[0] - b[0]) // Sort by year
        .map(([year, logins]) => [year, logins]);

      // Step 3: Set the formatted data
      setTimeData([["year", "logins"], ...formattedData]);
    }
  }, [userAnalytics]);

  const getMonthTimes = () => {
    if (userAnalytics.length > 0 &&  typeof userAnalytics[0] === 'object' ) {
      // Step 1: Extract the years and count occurrences
      const monthCounts = userAnalytics.reduce((acc, entry) => {
        const month = entry.time_logged_in.slice(5, 7); // Extract year as string
       
        acc[month] = (acc[month] || parseInt(month, 10)) + 1; // Increment count for the year
        return acc;
      }, {});
      
      // Step 2: Convert yearCounts into an array format compatible with the chart
      const formattedData = Object.entries(monthCounts)
        .sort((a, b) => a[0] - b[0]) // Sort by year
        .map(([month, logins]) => [month, logins]);
        const wordmattedData = Object.entries(monthCounts).map(([month, logins]) => [
          month === "01" ? "January" :
          month === "02" ? "February" :
          month === "03" ? "March" :
          month === "04" ? "April" :
          month === "05" ? "May" :
          month === "06" ? "June" :
          month === "07" ? "July" :
          month === "08" ? "August" :
          month === "09" ? "September" :
          month === "10" ? "October" :
          month === "11" ? "November" :
          month === "12" ? "December" :
          "Unknown",
          logins
        ]);

      // Step 3: Set the formatted data
      setMonthData([["month", "logins"], ...formattedData]);
    }
  }

  useEffect(() => {
    getMonthTimes();
  }, [userAnalytics]);

  const options = {
    title: "User Logins by Year",
    hAxis: { title: "Year" },
    vAxis: { title: "Logins" },
    legend: {position: "bottom"},
  };
  return (
    <Box>
      <Chart
        chartType="LineChart"
        width="100%"
        height="400px"
        data={monthData}
        options={options}
      />

      
    </Box>
  );
};

export default TimeGraph;
