import { Box, MenuItem, Select, Stack, TextField, Typography } from '@mui/material'
import React, { useState } from 'react'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { convertToObject, postData } from '../functions';


const DoubleClick = ({dropdown,demo,value,id, column, setUsersAsObj, usersAsObj}) => {

  const [textField, setTextField] = useState(value)
  const [editable, setEditable] = useState(false)

  function handleUpdate(newVal){
    let temp = usersAsObj
    for(let i = 0; i<temp.length; i++){
      if(temp[i].id === id){
        temp[i][column] = newVal
      }
    }
  
    setUsersAsObj([...temp])

    console.log([...temp])

    postData({table:'priceh_user',id:id,columnToUpdate:column,newValue:newVal},'update')
    setEditable(false)
  }
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleUpdate(textField)
      
    }
  };



  return (
    <Box sx={{ flex: 1, textAlign: 'center', }}>
      {
        editable === false &&
        <Typography onDoubleClick={()=>{setEditable(!editable)}}>{value}</Typography>
      }
      {
        dropdown === false && editable === true &&
        <TextField value={textField} onChange={(e)=>{setTextField(e.target.value)}} onKeyDown={handleKeyDown}/>
      }

      {
        demo && dropdown == false &&
        <ArrowUpwardIcon/>
      }
      
      {
        dropdown && editable &&
        <Select defaultValue={value} onChange={(e)=>{handleUpdate(e.target.value)}}>
                <MenuItem value={'Active'}>Active</MenuItem>
                <MenuItem value={'Inactive'}>Inactive</MenuItem>

        </Select>
      }
    </Box>
  )
}

export default DoubleClick