import { Box, Button, InputAdornment, Stack, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { convertToObject, getData, h,postData } from '../functions'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import InfoIcon from '@mui/icons-material/Info';
import Tooltip from '@mui/material/Tooltip';
import styles from '../styles';

import GlobalStyles from "@mui/material/GlobalStyles";
import Doctor from './Doctor';
const SignIn = ({userAnalyticsid, setUserAnalyticsid, setFacilityData, facilityData, procedureInfo, setProcedureInfo, setDoctorData, doctorData, signInData, setSignInData, setSavedData, savedData}) => {
  const style = styles;
  const navigate = useNavigate()
  const [pass, setPass] = useState('')
  const [user, setUser] = useState('')
  const [message, setMessage] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  
  useEffect(() => {
    if(signInData.length > 2 && typeof(signInData)!=='object'){
      let temp = convertToObject(signInData,['id','user','password','company_name','anesthesia_hourly','staff_expense_hourly', 'facility_hourly', 'email','status','account_type', 'room_and_board_cost', 'timestamp'])
      setSignInData(temp)
    }
    
  }, [signInData])

  useEffect(() => {
 
    if(procedureInfo.length > 2 && typeof(procedureInfo)!=='object'){
      
      let temp = convertToObject(procedureInfo,['id','procedure_code','procedure_or_surgery','implant_cost','user_id', 'timestamp'])
      setProcedureInfo(temp)
    }
    
  }, [procedureInfo])
  
  useEffect(() => {
    if(userAnalyticsid.length > 2 && typeof(userAnalyticsid)!=='object'){
    
      let temp = convertToObject(userAnalyticsid,['user_id','time_loggin_in','time_loggin_out','id'])
      setUserAnalyticsid(temp)
     
    }
    
  }, [userAnalyticsid, setUserAnalyticsid])
 


  const generateUniqueId = (userId) => {
    const today = new Date();

    const year = today.getFullYear();
    const lastTwoDigitsOfYear = String(year).slice(-2); // Last two digits of the year
    
    const month = today.getMonth() + 1; // Months are zero-based, so add 1
    const firstDigitOfMonth = String(month).charAt(0); // First digit of the month
    
    const day = today.getDate();
    const hours = today.getHours();
    const seconds = today.getSeconds();
    const firstDigitOfDay = String(day).charAt(0); // First digit of the day
    const firstHourOfDay = String(hours).charAt(0);
    const firstSecondOfDay = String(seconds);
    const uniqueId = `${userId}${lastTwoDigitsOfYear}${firstDigitOfMonth}${firstDigitOfDay}${firstHourOfDay}${firstSecondOfDay}`;
  
    return uniqueId;
  };

  const handleSubmit = () => {
    let found = false

    for(let i=0; i<signInData.length; i++){
      console.log(signInData)
      const storedPassword = Number(signInData[i].password);
      const hs = h(pass);
      console.log(signInData[i].password)
      console.log(h(pass))
         
      if(signInData[i].user === user && hs === storedPassword){
        window.localStorage.setItem('user',JSON.stringify(signInData[i]))
        setFacilityData(signInData[i])
  
        const data = getData('doctors', setDoctorData,Number(signInData[i].id))
        if (data === null || data === undefined) {
          setDoctorData([])
        } else {
          setDoctorData(data)
        }
      
       
        getData('records',setSavedData,Number(signInData[i].id))

        if (savedData !== Array) {
          setSavedData([])
        }
   
       setMessage('')
        found=true
        const uniqueTimeId = generateUniqueId(signInData[i].id)
        
        postData({
          user_id:signInData[i].id,
          time_logged_out: '',
          id: uniqueTimeId
      }, 'analytics');
      
      setUserAnalyticsid(uniqueTimeId)
      if(signInData[i].account_type === 'admin'){
        navigate('/admin')
      }else{
        navigate('/costAnalysis')
      }

      }
    }
    if(!found){
      setMessage('Incorrect')
    }
  }
 

  return (
    <Stack direction="row"sx={style.signInMainStack}>
    {/* 1st Box */}
   <Box sx={style.firstSignInBox }>

   </Box>
   {/* 2nd Box */}
   <Box  sx={style.secondSignInBox }>
     <Typography  sx={style.signInTitle} variant='h4'>Bundlr Demo</Typography>
     <Typography sx={style.signInSubTitle} variant='h6'>- Lets Get Started -</Typography>
     {/*<Typography sx={{marginTop:'50px'}} textAlign={'center'} variant='h5'>Sign In</Typography> */}
     {
       typeof(signInData[0]) === 'object' ?
       <Stack direction={'column'} sx={style.signInMainStack2}>
       <TextField 
         label="Username" 
         sx={style.signInMainTextField} 
         onChange={(e) => setUser(e.target.value)} 
         InputProps={{
           endAdornment: (
             <InputAdornment position="end">
                <Tooltip  title={<h2 style={style.toolTip}>Type in your username here</h2>} arrow>
                 <InfoIcon sx={style.thirdInfoInfo}  />
               </Tooltip>
             </InputAdornment>
           )
         }}
       />
        <GlobalStyles styles={style} />
       <TextField 
         label="Password" 
         type={showPassword? '' : 'password'}
         sx={style.signInSecondaryTextField} 
         onChange={(e) => setPass(e.target.value)} 
         InputProps={{startAdornment: (<InputAdornment position="start"><RemoveRedEyeIcon sx={{color:'white'}}onClick={()=>setShowPassword(!showPassword)}/></InputAdornment>), endAdornment: (
           <InputAdornment position="end">
             <Tooltip  title={<h2 style={style.toolTip}>Type in your password here</h2>} arrow>
               <InfoIcon sx={style.thirdInfoInfo}  />
             </Tooltip>
           </InputAdornment>
         )}}
        
       />
       <Typography sx={style.signInTypo}>{message}</Typography>
       <Button sx={style.signInTypoButton} variant='contained' onClick={handleSubmit}>
       Sign In</Button>
     </Stack>
       :
       <Typography sx={style.signInLoadingTypo} variant="h4" textAlign={'center'}>Loading...</Typography>
     }  
   </Box>
   {/* 3rd Box */}
   <Box sx={style.signInThirdBox}>
   

   </Box>
 </Stack>
  )
}

export default SignIn