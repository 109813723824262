import React, { useEffect, useState,useRef  } from 'react';
import { Box, Button, Grid, MenuItem, TextField, Typography, Select, Stack,InputAdornment, Checkbox } from '@mui/material';
import { Navbar } from '../component';
import { convertToObject, getData, postData } from '../functions';
import InfoIcon from '@mui/icons-material/Info';
import Tooltip from '@mui/material/Tooltip';
import styles from '../styles';

import './Cost.css';
import { useNavigate } from 'react-router-dom';


const CostAnalysis = ({ userAnalyticsid, setFacilityData, facilityData, procedureInfo, doctorData, setDoctorData, setProcedureInfo, setSavedData , savedData}) => {
  const style = styles;
  const boxRef = useRef();

  const [days, setDays] = useState(1)
  const [selectedDoctor, setSelectedDoctor] = useState('Select Doctor');
  const [procedure, setProcedure] = useState('');
  const [docsList, setdocsList] = useState([]);
  const [profit, setProfit] = useState(0);
  const [total, setTotal] = useState(0);
  const [anesthesia, setAnesthesia] = useState(facilityData.anesthesia_hourly);
  const [staffExpense, setStaffExpense] = useState(facilityData.staff_expense_hourly);
  const [facilityCost, setFacilityCost] = useState(facilityData.facility_hourly);
  const [RaBC, setRaBC] = useState(facilityData.room_and_board_cost);
  const [RaBCVis, setRaBCVis] = useState(false)
  const [fee, setFee] = useState(0);
  const [supply, setSupply] = useState(0);
  const [time, setTime] = useState(0);
  const [implants, setImplants] = useState(0);
  const [trigger, setTrigger] = useState(false);

  const [procedureList, setProcedureList] = useState([])
  const [dropDown, setDropDown] = useState(false)

  const [saveButtonColor, setSaveButtonColor] = useState('#23408e')
  const [saveButtonText, setSaveButtonText] = useState('Save')

  // for label visibility 
  const [isFocusedImplant, setIsFocusedImplant] = useState(false);
  const [isFocusedProfit, setIsFocusedProfit] = useState(false);
  const navigate = useNavigate()

  // to hide dropdown if mousedown somewhere else on the screen
  useEffect(() => {
    function handleClickOutside(event) {
      if (boxRef.current && !boxRef.current.contains(event.target)) {
        setDropDown(false); 
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []); 

  function selected(target, value) {
    let proceduresList = [];
    for (let i = 0; i < procedureInfo.length; i++) {
      proceduresList.push(procedureInfo[i].procedure_code);
    }
    if (target === 'doctor') {
      if (proceduresList.includes(procedure)) {
        let doctorFound = false;
        for (let i = 0; i < doctorData.length; i++) {
          if (doctorData[i].doctor === value && doctorData[i].procedure.toString() === procedure) {
            setFee(doctorData[i].surgeon_fee);
            setSupply(doctorData[i].supplies);
            setTime(doctorData[i].avg_time);
            doctorFound = true;
          }
        }
        if (!doctorFound) {
          setFee(0);
          setSupply(0);
          setTime(0);
        }
      }
    } else if (target === 'code' && proceduresList.includes(value)) {
      for (let i = 0; i < procedureInfo.length; i++) {
        if (procedureInfo[i].procedure_code === value) {
          setImplants(procedureInfo[i].implant_cost);
        }
      }
      if (selectedDoctor !== 'SelectDoctor') {
        let doctorFound = false;
        for (let i = 0; i < doctorData.length; i++) {
          if (doctorData[i].doctor === selectedDoctor && doctorData[i].procedure.toString() === value) {
            setFee(doctorData[i].surgeon_fee);
            setSupply(doctorData[i].supplies);
            setTime(doctorData[i].avg_time);
            doctorFound = true;
          }
        }
        if (!doctorFound) {
          setFee(0);
          setSupply(0);
          setTime(0);
        }
      }
    }
  }


  function save() {
    setSaveButtonColor('green')
    setSaveButtonText('Bundled!')
    let pastId = 0
    console.log(savedData)
    if(savedData.length>0){
      let temp = [...savedData]
      pastId = temp[temp.length-1].bundle_id
      temp.push({
        id: facilityData.id,
        procedure_id: procedure,
        base_cost: (anesthesia * time / 60) + (staffExpense * time / 60) + (facilityCost * time / 60) + Number(fee) + Number(supply) + Number(implants),
        total: total,
        profit_margin: profit,
        doctor:selectedDoctor,
        room_and_board_cost: RaBCVis ? RaBC : 0,
        bundle_id: pastId+1,
      
    })
    setSavedData(temp)
    }else{
      console.log('no saved Data')
      pastId = -1
      setSavedData([{
        id: facilityData.id,
        procedure_id: procedure,
        base_cost: (anesthesia * time / 60) + (staffExpense * time / 60) + (facilityCost * time / 60) + Number(fee) + Number(supply) + Number(implants),
        total: total,
        profit_margin: profit,
        doctor:selectedDoctor,
        room_and_board_cost: RaBCVis ? RaBC : 0,
        bundle_id: pastId+1,
        
    }])

    }
    
    
    postData({
      id: facilityData.id,
      procedure_id: procedure,
      base_cost: (anesthesia * time / 60) + (staffExpense * time / 60) + (facilityCost * time / 60) + Number(fee) + Number(supply) + Number(implants),
      total: total,
      profit_margin: profit,
      doctor:selectedDoctor,
      room_and_board_cost: RaBCVis ? RaBC : 0,
      bundle_id: pastId+1,
     
     
    }, 'records');
    
  }
  const handleSave = () => {
    //console.log(savedData)
    const result = procedureInfo.find(item => item.procedure_code === procedure)
    const ifDocHasProcCheck = doctorData.find((item => item.procedure === procedure && item.doctor === selectedDoctor ))
    const isRaBCValid = typeof RaBC === 'string' ? RaBC.length > 0 : RaBC > 0;
    const isSelectedDoctorValid = Array.isArray(selectedDoctor) ? selectedDoctor.length > 0 && selectedDoctor !== 'Select Doctor' : selectedDoctor.trim().length > 0 && selectedDoctor !== 'Select Doctor';
    const isProfitValid = typeof profit === 'string' ? profit.length > 0 : profit > 0;
    const isTotalValid = typeof total === 'string' ? total.length > 0 : total > 0;
    const isProcedureValid = typeof procedure === 'string' ? procedure.length > 0 : procedure > 0;
    

    //Checks to see if the account is a demo. If not then it sets bundles to 0 because they can have unlimited.
    let bundles = 0
    if(facilityData.account_type === 'demo'){
      for(let i = 0; i<savedData.length; i++){
        if(savedData[i].id === facilityData.id){
          bundles += 1
        }
      }
    }
    if (ifDocHasProcCheck) {
      if (result) {
        if ((RaBCVis ? isRaBCValid : true) &&isSelectedDoctorValid &&isProfitValid &&isTotalValid &&isProcedureValid) {
          if(bundles < 5){
            save();
          }else{
            alert('You have bundled the maximum amount for this demo')
          }
        } else {
          alert('One or more fields are empty');
        }
      } else {
        alert("Invalid CPT Code, please choose one from your list")
      }
    } else{
      alert("Your doctor does not have this CPT code, add it in the Doctor page")
    }
  };
  useEffect(() => {
    // Creating a timeout within the useEffect hook
    if(saveButtonColor === 'green'){
      setTimeout(() => {
        setSaveButtonText('Save')
        setSaveButtonColor('#23408e');
    }, 2000);
    }
}, [saveButtonColor]);

  // safeguard is user would click save again by accident
  const handleSaveCheck = () => {
    if (saveButtonColor !== 'green') {
      handleSave()
    }
  }

  useEffect(() => {
    const parsedRaBC = RaBCVis ? Number(RaBC) : 0;
    let base = Number(parsedRaBC)  + (anesthesia * time / 60) + (staffExpense * time / 60) + (facilityCost * time / 60) + Number(fee) + Number(supply) + Number(implants);
    setTotal((base * (1 + Number(profit) / 100)).toFixed(2));
  }, [anesthesia, time, staffExpense, facilityCost, fee, supply, implants, profit, RaBC, RaBCVis, days, setDays]);

  useEffect(() => {
    if (doctorData.length > 0 && typeof (doctorData[0]) !== 'object') {
      setDoctorData(convertToObject(doctorData, ['id','user_id', 'doctor', 'procedure', 'surgeon_fee', 'supplies', 'avg_time', 'timestamp']));
    }
    if (typeof (doctorData[0]) === 'object') {
      let temp = [];
      for (let i = 0; i < doctorData.length; i++) {
        if (!temp.includes(doctorData[i].doctor)) {
          temp.push(doctorData[i].doctor);
        }
      }
      setdocsList(temp);
    } else {
      setTrigger(!trigger);
    }
  }, [doctorData, trigger]);

  useEffect(() => {
    if (procedureInfo.length > 0 && typeof (procedureInfo[0]) !== 'object') {
      setProcedureInfo(convertToObject(procedureInfo, ['id', 'procedure_code', 'procedure_or_surgery', 'implant_cost', 'user_id', 'timestamp']));
      
    }
    if(procedureInfo.length > 0 && typeof (procedureInfo[0]) === 'object'){
  
      let temp = []
      for(let i = 0; i<procedureInfo.length; i++){
        if(procedureInfo[i].user_id === facilityData.id){
          temp.push(procedureInfo[i].procedure_code)
        }    
      }
      setProcedureList(temp)
    }
  }, [procedureInfo]);

  useEffect(() => {
    if (savedData.length > 0 && typeof (savedData[0]) !== 'object') {
      const temp = convertToObject(savedData, ['id', 'procedure_id', 'base_cost', 'total', 'profit_margin','doctor', 'room_and_board_cost', 'bundle_id', 'timestamp'])
      setSavedData(convertToObject(savedData, ['id', 'procedure_id', 'base_cost', 'total', 'profit_margin','doctor', 'room_and_board_cost', 'bundle_id', 'timestamp']));
      console.log(temp)
    }
  }, [savedData])
  

  useEffect(() => {
    
    if (facilityData.user === undefined) {
      let user = window.localStorage.getItem('user');
      user = JSON.parse(user);
      if(user !== null){
        setFacilityData(user);
        getData('doctors', setDoctorData, Number(user.id));
        getData('pricing', setProcedureInfo);
        getData('records', setSavedData)
        setAnesthesia(user.anesthesia_hourly);
        setStaffExpense(user.staff_expense_hourly);
        setFacilityCost(user.facility_hourly);
        setRaBC(user.room_and_board_cost)
      }else{
        navigate('/')
      }
      
    }
   
  }, []);

  useEffect(() => {
    if(RaBCVis){
      if(days === undefined){
        setDays(1)
        setRaBC(facilityData.room_and_board_cost)
      }else{
        setRaBC(days*facilityData.room_and_board_cost)
      }
    }
  }, [days,RaBCVis])
  




  return (
    <Box>
  <Navbar user={facilityData} userAnalyticsid={userAnalyticsid}/>
  <Grid container sx={style.containerMain}>
    {/* Left Column */}
    <Grid item xs={12} sm={12} lg={4} sx={style.leftColumnGridItem}>
      <Box sx={style.gridItemBoxColumn}>
        <Typography variant='h4' sx={style.typographyItemBoxColumn}>Procedure Cost</Typography>
      </Box>
      <Box sx={style.secondBoxColumn}>
        <Grid container spacing={3} sx={style.secondGridContainer}>
          <Grid item xs={6} sx={style.rightColumnGridItem}>
            <Typography sx={style.leftColumnHeader}>
              CPT Code
            </Typography>
            <TextField value={procedure} 
            onChange={(e) => {const value = e.target.value.replace(/[^0-9.]/g, ''); setProcedure(value); selected('code', value); setDropDown(true)}} sx={style.leftColumnTextField}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Tooltip  title={<h2 sx={style.toolTip}>Type in procedure code</h2>} arrow>
                    <InfoIcon sx={style.infoIcon}  />
                  </Tooltip>
                </InputAdornment>
              )
            }} />
              <Box   ref={boxRef} sx={{
                  position: 'absolute', 
                  top:"100px", borderRadius:'10px',backgroundColor: 'white', width: '80%',zIndex: 1,
                }}>
                  {procedure.length>0 && procedureList.length > 0 && dropDown &&
                    procedureList.filter(item => item.startsWith(procedure))
                    .map((item, idx) => (
                      <Typography
                        key={idx}
                        sx={{color: 'black', padding: '4px', cursor: 'pointer', borderRadius:'10px', '&:hover': {
                        backgroundColor: 'lightgrey', transform: 'scale(1.05)',  cursor: 'pointer',
                    },transition: 'all 0.3s ease'}} 
                        onClick={() => {setProcedure(item,setDropDown(false)); selected('code', item)}} // Fill TextField on click
                      >
                        {item}
                      </Typography>
                    ))
                  }
            </Box>
          </Grid>
          <Grid item xs={6} sx={style.rightColumnGridItem}>
            <Typography sx={style.leftColumnHeader}>
              Doctor
            </Typography>
            <Select  MenuProps={{ disableScrollLock: true }} label={'Procedure Cost'} value={selectedDoctor} onChange={(e) => { setSelectedDoctor(e.target.value); selected('doctor', e.target.value); }} sx={style.selectMenu2}>
              <MenuItem value={'Select Doctor'}>Select Doctor</MenuItem>
              {docsList.length > 0 &&
                docsList.map((item, idx) => (
                  <MenuItem key={idx} value={item}>{item}</MenuItem>
                ))}
            </Select>
          </Grid>
          <Grid item xs={6} sx={style.rightColumnGridItem}>
            <Typography sx={style.leftColumnHeader}>
              Implant Cost
            </Typography>
            <TextField label="0" InputLabelProps={{ shrink: false, sx: {visibility: isFocusedImplant || implants ? "hidden" : "visible"}, }} onFocus={() => setIsFocusedImplant(true)}  onBlur={() => setIsFocusedImplant(false)}
            sx={style.leftColumnTextField} value={implants === 0 ? null : implants} onChange={(e) => {const value = e.target.value.replace(/[^0-9.]/g, ''); setImplants(value); }} />
          </Grid>
          <Grid item xs={6} sx={style.rightColumnGridItem}>
            <Typography sx={style.leftColumnHeader}>
              Profit %
            </Typography>
            <TextField label="0" InputLabelProps={{ shrink: false, sx: {visibility: isFocusedProfit || profit ? "hidden" : "visible"}}} value={profit === 0 ? null : profit} onFocus={() => setIsFocusedProfit(true)}  onBlur={() => setIsFocusedProfit(false)} onChange={(e) => { const value = e.target.value.replace(/[^0-9.]/g, ''); setProfit(value); }} sx={style.leftColumnTextField}
              InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Tooltip  title={<h2 sx={style.toolTip}>Type in desired profit margin (in percentage)</h2>} arrow>
                    <InfoIcon sx={style.infoIcon}  />
                  </Tooltip>
                </InputAdornment>
              )
            }} />
          </Grid>
        </Grid>
       
      </Box>
 
    </Grid>

    {/* Middle Column */}
    <Grid item xs={12} sm={12} lg={4} sx={style.middleColumnGridItem}>
    <Box sx={style.middleColumnBox1}>
        <Typography variant='h4' >Facility Costs</Typography>
      </Box>
      
      <Box sx={style.middleColumnBox2}>
        <Grid container spacing={3} sx={style.middleColumnContainer}>
        <Grid item xs={6}  sx={style.firstRowMiddleItem}> 
        <Typography sx={style.middleColumnHeader}>
          Anesthesia
        </Typography>
        <Stack direction="row" alignItems="center" sx={style.stackUnderAnesthesia}>
          <Box sx={style.anesthesiaBox}>
            <TextField  sx={{ ...style.anesthesiaTextfield }} value={anesthesia} onChange={(e) => { const value = e.target.value.replace(/[^0-9.]/g, ''); setAnesthesia(value); }} />
            <Typography  sx={style.anesthesiaTypo2} >
              x {(time/60).toFixed(2)} hrs = {(anesthesia * time / 60).toFixed(2)}
            </Typography>
          </Box>
        </Stack>
        </Grid>
        <Grid item xs={6} sx={style.firstRowMiddleItem}> 
        <Typography sx={style.middleColumnHeader}>
          Staff expense
        </Typography>
        <Stack direction="row" alignItems="center" sx={style.expenseStack}>
          <Box sx={style.expenseBox}>
            <TextField sx={{ ...style.expenseTextfield }} value={staffExpense} onChange={(e) => {const value = e.target.value.replace(/[^0-9.]/g, '');  setStaffExpense(value); }} />
            <Typography sx={style.staffexpenseTypo2} textAlign="center">  x {(time/60).toFixed(2)} hrs = {(staffExpense * time / 60).toFixed(2)} </Typography>
          </Box>
        </Stack>
        </Grid>
        <Grid item xs={6} sx={style.secondRowMiddleItem}> 
        <Typography sx={style.middleColumnHeader}>
          Facility
        </Typography>
        <Stack direction="row" alignItems="center" sx={style.facilltyStack}>
          <Box sx={style.facilityBox}>
            <TextField sx={style.facilityTextField} value={facilityCost} onChange={(e) => { const value = e.target.value.replace(/[^0-9.]/g, ''); setFacilityCost(value); }} />
            <Typography sx={style.facilityTypo2}> x {(time / 60).toFixed(2)} hrs = {(facilityCost * time / 60).toFixed(2)}</Typography>
          </Box>
        </Stack>
        </Grid>
        <Grid item xs={6} sx={style.secondRowMiddleItem}>
          <Typography  sx={style.middleColumnHeader}>
            R and B (Daily)
          </Typography>
          <Stack direction="row" alignItems="center" sx={style.roomABStack}>
            <TextField sx={style.roomTextField} disabled={!RaBCVis} value={RaBCVis ?  RaBC : 0} onChange={(e) => {const value = e.target.value.replace(/[^0-9.]/g, ''); setRaBC(value)}} />
            <Checkbox sx={{margin:'-10px'}} onClick={() => setRaBCVis(!RaBCVis, setRaBC(facilityData.room_and_board_cost, setDays()))} />
            
            <TextField sx={style.amountOfDayTextfield}value={days} disabled={!RaBCVis} onChange={ (e) => {const value = e.target.value.replace(/[^0-9.]/g, ''); setDays(value)}} size="small"   InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Tooltip  title={<h2 sx={style.toolTip}>Type the amount of days for Room and Board cost </h2>} arrow>
                    <InfoIcon sx={style.infoIconSmall}  />
                  </Tooltip>
                </InputAdornment>
              )
            }}/>
          </Stack>
            
          </Grid>
        </Grid>
      </Box>
    </Grid>

    {/* Right Column */}
    <Grid item xs={12} sm={12} lg={4}  sx={style.rightColumnItem}>
      <Box sx={style.rightColumnBox}>
        <Typography variant='h4'>Doctor Costs</Typography>
      </Box>
        <Box sx={style.rightColumnBox2}>
        <Grid container spacing={3} sx={style.rightColumnContainer}>
          <Grid item xs={6} sx={style.rightColumnGridItem}>
            <Typography sx={style.rightColumnHeader}>
              Surgeon Fee
            </Typography>
            <TextField sx={style.rightColumnTextField} value={fee} onChange={(e) => {const value = e.target.value.replace(/[^0-9.]/g, '');  setFee(value);}}/>
          </Grid>
          <Grid item xs={6}sx={style.rightColumnGridItem} >
            <Typography sx={style.rightColumnHeader}>
              Supply Cost
            </Typography>
            <TextField sx={style.rightColumnTextField} value={supply} onChange={(e) => {const value = e.target.value.replace(/[^0-9.]/g, '');  setSupply(value); }} />
          </Grid>
          
          <Grid item xs={12} sx={style.rightColumnGridItem2}>
            <Typography sx={style.rightColumnHeader2}>
              Average Time
            </Typography>
            <TextField sx={style.rightColumnTextField2} value={time} onChange={(e) => {const value = e.target.value.replace(/[^0-9.]/g, ''); setTime(value); }}/>
          </Grid>
          </Grid>
        </Box>
    </Grid>
  </Grid>
  <Grid container sx={style.totalGridContainer}>  
    <Grid item xs={12}>
      <Stack direction='row'>
      <Box sx={style.totalBox}>
          <Typography variant='h5' sx={style.totalTypo}>
            Total
          </Typography>
          <Typography variant='h5'>{total}</Typography>
          <Button sx={{backgroundColor: saveButtonColor,
 '&:hover': {  transform: 'scale(1.05)', backgroundColor:saveButtonColor}, transition: 'background-color 1000ms linear' }} variant='contained' onClick={()=> handleSaveCheck()}>
            {saveButtonText}
          </Button>
          </Box>
          
      </Stack>
      </Grid>
     
    </Grid>
</Box>

  );
}

export default CostAnalysis;