import { Button, Stack, Typography, } from '@mui/material'
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'
import { postData } from '../functions'
// responsivness
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
//import styles from '../styles';

const AdminBar = ({user, userAnalyticsid}) => {
  //const style = styles;

  const navigate = useNavigate()
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.down('lg'));
  // for hamburger menu
  const [anchorEl, setAnchorEl] = useState(null);


  useEffect(() => {
    const handleBeforeUnload = (event) => {
      const exitDateTime = getFormattedDate();
      postData(
        { table: 'user_analytics', id: userAnalyticsid, columnToUpdate: 'time_logged_out', newValue: exitDateTime },
        'update'
      );

      // Prevent default unload behavior
      event.preventDefault();
      event.returnValue = ''; // This is required for Chrome
    };

    // Add the event listener for beforeunload to detect the window close event
    window.addEventListener('beforeunload', handleBeforeUnload);
    
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  const getFormattedDate = () => {
    const date = new Date();
  
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
  
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };



  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (destination) => {
    sendTo(destination)
    if(destination === ''){
      localStorage.removeItem("user");
      
    }

    handleClose(); // Close menu after handling click
  
  };
  // end of hamburger menu

  function sendTo(link){
    if(link === ''){
      const exitDateTime = getFormattedDate()
     
      postData({table:'user_analytics',id:userAnalyticsid,columnToUpdate:'time_logged_out',newValue: exitDateTime },'update')
      localStorage.removeItem("user");
  
    }
    navigate(`/${link}`)
  }

  
  const buttonStyle = {
    color: "black", borderRadius:'20px', marginLeft:'10px',
    width:"100px", height:'70%', padding:'10px 60px 10px 60px',
    
    transition: 'transform 0.3s, background-color 0.3s',
    '&:hover': { backgroundColor: 'lightgrey', transform: 'scale(1.05)' },
  }
  
 
   
   
  if (isLargeScreen) {
    return (
      <Stack direction={'row'} sx={{ boxShadow: '0 8px 12px rgba(0, 0, 0, 0.3)',display:'flex', justifyContent:'center', alignItems:'center', background:'#23408e', position:'absolute', top:'0', left:'0', right:'0',height:'100px'}} >
      <Stack sx={{marginLeft:'20%', marginRight:'auto'}}>
          <Typography variant='h4' sx={{color:'white', }}>Bundlr Admin</Typography>

      </Stack>
      <>
      <IconButton
        size="large"
        edge="start"
        color="inherit"
        aria-label="menu"
        aria-controls="dropdown-menu"
        aria-haspopup="true"
        onClick={handleClick} sx={{marginRight:'50px', color:'white'}}
      >
        <MenuIcon />
      </IconButton>
      <Menu
        id="dropdown-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={() => handleMenuItemClick('admin')}>
          Dashboard
        </MenuItem>
        <MenuItem onClick={() => handleMenuItemClick('admin/analytics')}>
          Analytics
        </MenuItem>
        <MenuItem onClick={() => handleMenuItemClick('admin/userManagement')}>
          User Management
        </MenuItem>
        <MenuItem onClick={() => handleMenuItemClick('')}>
          Logout
        </MenuItem>
      </Menu>
    </>
      
    </Stack>
    );
  } else {
    return (
      <Stack direction={'row'} sx={{ boxShadow: '0 8px 12px rgba(0, 0, 0, 0.3)',display:'flex', justifyContent:'center', alignItems:'center', background:'#23408e', position:'absolute', top:'0', left:'0', right:'0',height:'100px'}} >
      <Stack sx={{marginLeft:'150px', marginRight:'auto'}}>
          <Typography variant='h4' sx={{color:'white', }}>Bundlr Admin</Typography>          
      </Stack>
      

      <Stack  direction={{ xs: 'column', md: 'row' }} sx={{  boxShadow: '0 8px 12px rgba(0, 0, 0, 0.3)', padding: { md:'2px', xxl:'8px'},marginRight:'100px',backgroundColor:'white', borderRadius:'20px',display:'flex', justifyContent:'center', alignItems:'center',}}>
        <Button onClick={()=>{sendTo('admin')}}  sx={buttonStyle}>Dashboard</Button>
        <Button onClick={()=>{sendTo('admin/analytics')}} sx={buttonStyle }>Analytics</Button>
        <Button onClick={()=>{sendTo('admin/userManagement')}} sx={buttonStyle}>User Management</Button>

        <Button onClick={()=>{sendTo('')}} sx={buttonStyle}>Logout</Button>
      </Stack>

    </Stack>

    );
  }
};
      
    
  


export default AdminBar