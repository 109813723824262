import React from 'react';
import { Page, Text, Document, View } from '@react-pdf/renderer';
import styles from '../styles';



const CPDF = ({ data, procedure }) => {
  const style = styles;
  return (
    <Document >
      <Page size="A4"  style={style.CPDFpage} >
        <View style={style.CPDFheader1}>
          <Text style={style.CPDFheaderText1}>Procedure Comparison</Text>
        </View>
        <View style={style.CPDFheader}>
          <Text style={style.CPDFheaderText}>Procedure: {procedure}</Text>
        </View>
        <View style={style.CPDFtableHeader}>
          <Text style={style.CPDFtableHeaderText}>Doctor Name</Text>
          <Text style={style.CPDFtableHeaderText}>Procedure Cost</Text>
          <Text style={style.CPDFtableHeaderText}>Procedure Time</Text>
          <Text style={style.CPDFtableHeaderText}>Procedure Cost Per Hour</Text>
        </View>
        {data.map((item, index) => (
          <View key={index} style={style.CPDFtableRow}>
            <Text style={style.CPDFtableCell}>{item.name}</Text>
            <Text style={style.CPDFtableCell}>{item.full}</Text>
            <Text style={style.CPDFtableCell}>{item.time} minutes</Text>
            <Text style={style.CPDFtableCell}>{item.costPH}</Text>
          </View>
        ))}
      </Page>
    </Document>
  );
};
export default CPDF;
