import $ from "jquery";

//in development - http://localhost/react-backend/

//in Production - https://api.surgicalbundlr.com
const prodUrl ='http://localhost/react-backend/'

export function getData(query,setData,id='0'){
    if(query === 'doctors'){
        $.ajax({
            type: "GET",
            url: `${prodUrl}?${query}&userId=${id}`,
            success(data) {
              
                setData(data)
            }
        })
    } else {
        $.ajax({
            type: "GET",
            url: `${prodUrl}?${query}`,
            success(data) {
                
                setData(data)
                
            },
        })
    }
    ;

  };


  export function postData(data, query) {
    $.ajax({
        type: 'POST',
        url: `${prodUrl}?${query}`,
        data: data,
    })
    .done((response) => {
        console.log("Success:", response);
    })
    .fail((jqXHR, textStatus, errorThrown) => {
        console.error("Error:", textStatus, errorThrown);
    });
}


  export function convertToObject(data, objList) {
    // Clean up the input data
 
    data = data.trim().replace(/\r\n|\r|\n/g, ','); // Replace all newline characters with commas

    // Split the data into a list
    let dataAsList = data.split(',').map(item => item.trim()); // Trim each item to remove extra whitespace

    // Calculate the number of complete objects we can make
    let tableLength = Math.floor(dataAsList.length / objList.length);

    let outputList = [];
    let addNumber = 0;

    for (let i = 0; i < tableLength; i++) {
        let outputObject = {};

        for (let j = 0; j < objList.length; j++) {
            let value = dataAsList[j + addNumber];
            
            // Assign the value to the respective key in the object
            // If value is empty (""), assign it as null or any placeholder value
            outputObject[objList[j]] = value === "" ? null : value;
        }

        addNumber += objList.length;
        outputList.push(outputObject);
    }
    return outputList;
}


export function h(a) {
    let b = 0;
    for (let c = 0; c < a.length; c++) {
        const d = a.charCodeAt(c);
        b = (b * 31 + d) % (2**32);
    }
    return b;
}