import React, {useState,useEffect} from 'react'
import AdminBar from '../../component/AdminBar'
import { Box,Stack,Typography,Select,MenuItem, Button, TextField, Menu } from '@mui/material'
import { convertToObject } from '../../functions'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import DoubleClick from '../../component/DoubleClick';

const UserManagement = ({facilityData, setFacilityData,userManagementData, setUserManagementData, userAnalyticsid}) => { 
  
  const [usersAsObj, setUsersAsObj] = useState([])
  const [newUser, setNewUser] = useState(false)

  useEffect(() => {
    if(userManagementData.length > 0 && typeof(userManagementData) !== 'object'){
      setUsersAsObj(convertToObject(userManagementData, ['id','user','password','company_name','anesthesia_hourly','staff_expense_hourly', 'facility_hourly', 'email','status','account_type', 'room_and_board_cost', 'timestamp']))
    }
  }, [userManagementData])
  

  return (
    <Box>
      <AdminBar user={facilityData} userAnalyticsid={userAnalyticsid}/>
      UserManagement  
        <Box sx={{
                mt:'100px', ml:'auto', mr:'auto',
                backgroundColor: 'white',
                borderRadius: '10px',
                padding: '10px',
                width: '80%',
              }}>
          <Typography
            variant="h4"
            sx={{ color: 'white', display: 'flex', justifyContent: 'center' }}
          >
          User Management
        </Typography>
        <Stack direction={'row'} s>
          <Typography variant='h5' sx={{ flex: 1, textAlign: 'center' }}>user</Typography>
          <Typography variant='h5' sx={{ flex: 1, textAlign: 'center' }}>company name</Typography>
          <Typography variant='h5' sx={{ flex: 1, textAlign: 'center' }}>email</Typography>
          <Typography variant='h5' sx={{ flex: 1, textAlign: 'center' }}>status</Typography>
          <Typography variant='h5' sx={{ flex: 1, textAlign: 'center' }}>account type</Typography>
          <Typography variant='h5' sx={{ flex: 1, textAlign: 'center' }}>time created</Typography>
        </Stack>
      
        {
          usersAsObj.length > 0 && 
          usersAsObj.map((user,idx)=>(
            <>
            {
                user.account_type !== 'admin' &&
               <Stack direction={'row'} key={idx} alignItems="center"
               sx={{
                   margin: '10px 0', 
                   padding: '10px',
                   backgroundColor:
                     idx % 2 === 0 ? 'white' : 'lightgray',
                   borderRadius: '5px',
                 }}>
                 
           
                 <DoubleClick 
                   demo={facilityData.account_type==='demo'} 
                   dropdown={false} 
                   value={user.user} 
                   id={user.id} 
                   column={'user'}
                   setUsersAsObj={setUsersAsObj}
                   usersAsObj={usersAsObj}
                   />
   
                 <DoubleClick 
                 demo={facilityData.account_type==='demo'} dropdown={false} 
                 value={user.company_name} 
                 id={user.id}
                 column={'company_name'}
                 setUsersAsObj={setUsersAsObj}
                 usersAsObj={usersAsObj}
                               />
   
                 <DoubleClick 
                 demo={facilityData.account_type==='demo'} dropdown={false} 
                 value={user.email} 
                 id={user.id}
                 column={'email'}
                 setUsersAsObj={setUsersAsObj}
                 usersAsObj={usersAsObj}              />
                 
                 <DoubleClick 
                   demo={facilityData.account_type==='demo'} 
                   dropdown={true} 
                   value={user.status} 
                   id={user.id}
                   column={'status'}
                   setUsersAsObj={setUsersAsObj}
                   usersAsObj={usersAsObj}                />
   
                 <DoubleClick 
                 demo={facilityData.account_type==='demo'} dropdown={false} 
                 value={user.account_type} 
                 id={user.id}
                 column={'account_type'}
                 setUsersAsObj={setUsersAsObj}
                 usersAsObj={usersAsObj}              />
   
                 <DoubleClick 
                 demo={facilityData.account_type==='demo'} dropdown={false} 
                 value={user.timestamp} 
                 id={user.id}
                 column={'timestamp'}
                 setUsersAsObj={setUsersAsObj}
                 usersAsObj={usersAsObj}              />
                 
   
                </Stack>
            }
           
              </>
          ))
        }
        </Box>
        <Box 
            sx={{ 
              display: 'flex', 
              justifyContent: 'center', 
              alignItems: 'center', 
              mt:"20px"
            }}>
            {
              newUser &&
              <Stack direction={'stack'}>
                <Stack direction={'row'}>
                  <TextField label={'username'}/>
                  <TextField label={'password'}/>
                  <TextField label={'company name'}/>
                  <TextField label={'email'}/>
                  <Select>
                    <MenuItem>Active</MenuItem>
                    <MenuItem>Inactive</MenuItem>
                  </Select>
                  <Select>
                    <MenuItem>Demo</MenuItem>
                    <MenuItem>Full</MenuItem>
                  </Select>
                  <TextField label={'company name'}/>

                </Stack>
                
              </Stack>
            }
            <Button onClick={()=>{setNewUser(true)}} sx={{width:'200px',backgroundColor: '#23408e',
              '&:hover': {  transform: 'scale(1.05)', backgroundColor:'#23408e'}, transition: 'background-color 1000ms linear' }}  variant="contained">Add User</Button>
          </Box>
        
        
     
    </Box>
  )
}

export default UserManagement