import { BrowserRouter, Routes, Route } from 'react-router-dom'
import {CostAnalysis, Doctor, Hospital, Comparisons, Saved, SignIn, SubmitcPDF, Admin, Home} from './pages'
import Analytics from './pages/adminPages/Analytics'
import UserManagement from './pages/adminPages/UserManagement'
import { useEffect, useState } from 'react';
import {getData } from './functions';

// to change the font
import { ThemeProvider, createTheme } from '@mui/material/styles';
import './App.css';

function App() {
  //Sign in to get the user\
    
    const [signInData, setSignInData] = useState([])
    const [doctorData, setDoctorData] = useState([])
    const [procedureInfo, setProcedureInfo] = useState([])
    const [facilityData, setFacilityData] = useState([])
    const [savedData, setSavedData] = useState([])

    // for analytics
    const [userAnalyticsid, setUserAnalyticsid] = useState([])
    const [userAnalytics, setUserAnalytics] = useState([])
   

    //for pdf data conversion
    const [pdfCompData, setPdfCompData] = useState([])

    // moved from comparisons so i can use it for the pdf
    const [procedure, setProcedure] = useState('')
    useEffect(() => {
      getData('pricing', (data) => {
        if (data.length > 0) {
            setProcedureInfo(data);
        } else {
            setProcedureInfo([]);
        }
      });

      getData('analytics', setUserAnalyticsid);
    
      getData('analytics', setUserAnalytics);
    
      getData('passwords',setSignInData)
      }, [])
    
    // to change font family
    const theme = createTheme({
      typography: {
        fontFamily: [
          'Nunito',
          'Roboto',
          '"Helvetica Neue"',
          'Arial',
          'sans-serif'
        ].join(','),
      },
      breakpoints: {
        values: {
          xs: 0,
          sm: 600,
          md: 900,
          lg: 1200,
          xl: 1536,
          xxl: 1900
        },
      },
    }); 
    const oswaldTheme = createTheme({
      typography: {
        fontFamily: [
          'Oswald',
          'Roboto',
          '"Helvetica Neue"',
          'Arial',
          'sans-serif'
        ].join(','),
      }, 
      breakpoints: {
        values: {
          xs: 0,
          sm: 600,
          md: 900,
          lg: 1200,
          xl: 1536,
          xxl: 1900
        },
      },
    });
    return (
        <ThemeProvider theme={theme}>
        <BrowserRouter>
            <Routes>
                {/*
                <Route path={'/'} element={<ThemeProvider theme={oswaldTheme}><Home /> </ThemeProvider>} />
                */}
                <Route path={'/'} element={<SignIn
                setUserAnalyticsid={setUserAnalyticsid}
                userAnalyticsid={userAnalyticsid}
                setSavedData={setSavedData}
                savedData={savedData}
                setFacilityData={setFacilityData}
                signInData={signInData}
                setSignInData={setSignInData}
                setProcedureInfo={setProcedureInfo}
                procedureInfo={procedureInfo}
                setDoctorData={setDoctorData}
                doctorData={doctorData}
                />}/>
                <Route path={'/costAnalysis'} element={<CostAnalysis
                    userAnalyticsid={userAnalyticsid}
                    setSavedData={setSavedData}
                    savedData={savedData}
                    setProcedureInfo={setProcedureInfo}
                    doctorData={doctorData}
                    setDoctorData={setDoctorData}
                    procedureInfo={procedureInfo}
                    facilityData={facilityData}
                    setFacilityData={setFacilityData}

                />} />
                
                <Route path={'/doctor'} element={
                    <Doctor
                        userAnalyticsid={userAnalyticsid}
                        savedData={savedData}
                        setSavedData={setSavedData}
                        doctorData={doctorData}
                        setDoctorData={setDoctorData}
                        facilityData={facilityData}
                        setFacilityData={setFacilityData}
                        setProcedureInfo={setProcedureInfo}
                        procedureInfo={procedureInfo}
                    />} />
                <Route path={'/facility'} element={<Hospital 
                
                userAnalyticsid={userAnalyticsid}
                facilityData={facilityData} 
                setFacilityData={setFacilityData}
                procedureInfo={procedureInfo}
                setProcedureInfo={setProcedureInfo}
                setDoctorData={setDoctorData}
                setSavedData={setSavedData}
                />} />
                <Route path={'/procedure'} element={<Comparisons procedureInfo={procedureInfo} setProcedureInfo={setProcedureInfo}
                userAnalyticsid={userAnalyticsid}
                doctorData={doctorData}
                setDoctorData={setDoctorData}
                facilityData={facilityData}
                setFacilityData={setFacilityData}
                setSavedData={setSavedData}
                setPdfCompData={setPdfCompData}
                pdfCompData={pdfCompData}
                setProcedure={setProcedure}
                procedure={procedure}
                />} />
                <Route path={'/saved'} element={<Saved
                  userAnalyticsid={userAnalyticsid}
                  savedData={savedData}
                  setSavedData={setSavedData}
                  facilityData={facilityData}
                  setFacilityData={setFacilityData}
                  setProcedureInfo={setProcedureInfo}
                  setDoctorData={setDoctorData}
                  setProcedure={setProcedure}
                  procedure={procedure}
                  doctorData={doctorData}
                />} />

                <Route path={'/SubmitcPDF'} element={<SubmitcPDF userAnalyticsid={userAnalyticsid} facilityData={facilityData} procedure={procedure} pdfCompData={pdfCompData} setPdfCompData={setPdfCompData}/>} />
                
                
                <Route path={'/admin'} element={<Admin setUserAnalytics={setUserAnalytics} userAnalytics={userAnalytics} userAnalyticsid={userAnalyticsid} facilityData={facilityData} setFacilityData={setFacilityData} setUserManagementData={setSignInData} />} />
                <Route path={'/admin/analytics'} element={<Analytics procedureInfo={procedureInfo} setProcedureInfo={setProcedureInfo} setUserAnalytics={setUserAnalytics} userAnalytics={userAnalytics} userAnalyticsid={userAnalyticsid} facilityData={facilityData} setFacilityData={setFacilityData}/>} />

                <Route path={'/admin/userManagement'} element={<UserManagement setUserManagementData={setSignInData} userManagementData={signInData} doctorData={doctorData} procedureInfo={procedureInfo} userAnalytics={userAnalytics} userAnalyticsid={userAnalyticsid} facilityData={facilityData} setFacilityData={setFacilityData}/>} />

            </Routes>
        </BrowserRouter>
        </ThemeProvider>
    );
}
 
export default App;