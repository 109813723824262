

import React, { useState, useEffect} from 'react'
// import { EmailSubmit } from '../../components'
import { Box, Stack, TextField, Typography, Button } from '@mui/material'
import { PDFViewer } from '@react-pdf/renderer'
import {CPDF, EmailSubmit} from '../component'
import styles from '../styles';
import { postData } from '../functions'
import { useNavigate } from 'react-router-dom'

const SubmitcPDF = ({pdfCompData, procedure, facilityData, userAnalyticsid}) => {
  
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      const exitDateTime = getFormattedDate();
      postData(
        { table: 'user_analytics', id: userAnalyticsid, columnToUpdate: 'time_logged_out', newValue: exitDateTime },
        'update'
      );

      // Prevent default unload behavior
      event.preventDefault();
      event.returnValue = ''; // This is required for Chrome
    };

    // Add the event listener for beforeunload to detect the window close event
    window.addEventListener('beforeunload', handleBeforeUnload);
    
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  const getFormattedDate = () => {
    const date = new Date();
  
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
  
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };

  const style = styles;
  const navigate = useNavigate()

  useEffect(() => {
    if (facilityData.user === undefined) {
      navigate('/procedure')
    }
     
      
  }, [])
  const [emailField, setEmailField] = useState(facilityData.email)

  
  return (
    <Box sx={style.subCPDFMainBox}>
    <Typography variant='h2' sx={style.subCPDFSubmissionTypo}>
      Submission
    </Typography>
    <Typography sx={style.subCPDFDirectionTypo}>
      Fill out the Following Fields and then press Submit
    </Typography>
    <Stack sx={style.subCPDFMainStack}>
      <TextField sx={style.subCPDFTextField} label='Type Email' value={emailField} onChange={(e)=>{setEmailField(e.target.value)}}/>
      {/* pdfviewer doesnt like using sx i guesss */}
      <PDFViewer height={'600px'} width={'80%'}>
        <CPDF data={pdfCompData} procedure={procedure}/>
        </PDFViewer>
      </Stack>
    
   
      <Stack direction="row" sx={style.emailSubmitMainStack}>
        <EmailSubmit PDF={ <CPDF data={pdfCompData} procedure={procedure}/>}  title={'bundlr_Comparsions.pdf'} toEmail={emailField}/>
        <Button sx={style.emailSubmitButton2} onClick={() => navigate('/procedure')}>Cancel</Button>
      </Stack>
      
    </Box>
  )
}

export default SubmitcPDF