import { Box, Typography, Stack, MenuItem, Pagination, TextField, Button, InputAdornment, Select, CardActionArea, Card } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Navbar } from '../component'
import { convertToObject, getData, postData } from '../functions';
import SearchIcon from '@mui/icons-material/Search';
import Tooltip from '@mui/material/Tooltip';
import FormControl from '@mui/material/FormControl';
import InfoIcon from '@mui/icons-material/Info';
import InputLabel from '@mui/material/InputLabel';
import { useNavigate } from 'react-router-dom';
import styles from '../styles';
// records page
const Saved = ({userAnalyticsid, savedData, setSavedData, facilityData, setFacilityData, setProcedureInfo, setDoctorData, doctorData}) => {

  const style = styles;
  // Copy of OG data
  const [fullFilteredData, setFullFilteredData] = useState([])
  //just the parts showing
  const [paginatedData, setPaginatedData] = useState([])
  // set dropdown category input
  const [category, setCategory] = useState('')

    // This one will active the getData function
  const [trigger2, setTrigger2] = useState(false)
  // This one will instead update the list upon a deletion via popup
  const [popupVis, setPopupVis] = useState(false)
  
  const [tempBundle, setTempBundle] = useState([])
  const [trigger, setTrigger] = useState(false)
  //everything in the menu
  const [filiteredData, setFiliteredData] = useState([])

  const [pageMax, setPageMax] = useState(0)
  // storage of Data from search bar
  const [termFind, setTermFind] = useState('')
  const [currentPage, setCurrentPage] = useState(1);

  // Slice the filtered data based on the current page
  const startIndex = (currentPage - 1) * 6;
  const endIndex = startIndex + 6;

  // for menu vis 
  const [isFocused, setIsFocused] = useState(false);
  const navigate = useNavigate()


  useEffect(() => {
    if (facilityData.user === undefined) {
      let user = window.localStorage.getItem('user');
      user = JSON.parse(user);
      if(user !== null){
        setFacilityData(user);
        getData('records', setSavedData); 
        getData('doctors', setDoctorData, Number(user.id));
        getData('pricing', setProcedureInfo);
      }else{
        navigate('/')
      }
    }  
  }, []);

  useEffect(() => {
    if (savedData.length > 0 && typeof (savedData[0]) !== 'object') {
      setTrigger(!trigger)
      setSavedData(convertToObject(savedData, ['id', 'procedure_id', 'base_cost', 'total','profit_margin', 'doctor', 'room_and_board_cost','bundle_id', 'timestamp']));
    }
    if(savedData.length > 0 && typeof (savedData[0]) === 'object'){
      // get all the indexes in between, according to the current page
      setFiliteredData(savedData.filter(item => item.id === facilityData.id))
      setFullFilteredData(savedData.filter(item => item.id === facilityData.id))
    }
  }, [savedData,trigger]);
  
  useEffect(() => {
    setPageMax( Math.ceil(filiteredData.length / 6))
  }, [filiteredData])

  
  const handleIconClick = () => { 
    if (termFind.length > 0) {
    setCurrentPage(1)
    if(category !== 'all'){
      let temp = []
      for(let i = 0; i<fullFilteredData.length;i++){ 
        if (
          fullFilteredData[i][category] &&
          fullFilteredData[i][category].toString().toLowerCase().includes(termFind.toLowerCase())
        ) {
          temp.push(fullFilteredData[i])
        }
      }
      setFiliteredData(temp)
    } else{
      setFiliteredData(fullFilteredData)
    }
  } else {
    setFiliteredData(fullFilteredData)
  }
  };

  const handleKeyDown = (e) => {
    if (termFind.length > 0) {
    if (e.key === 'Enter' && termFind.length > 0) {
      handleIconClick();
    }
    } else {
      setFiliteredData(fullFilteredData)
    }
  }
  useEffect(() => {
    setPaginatedData(filiteredData.slice(startIndex, endIndex));
  }, [filiteredData, currentPage]);

// before functions are for Popup confirmations
  useEffect(() => {
 
   getData('records', setSavedData);
   setTrigger2(false)
 }, [trigger2]);
 
  const handleBundleClick = (item) => {
    if (!popupVis) {
      setTempBundle(item)
      setPopupVis(true)
    }
  }

  const handleConfirmClick = (value) => {
    if (value === 'yes') {
      postData({bundle_id:tempBundle.bundle_id, procedure_id:tempBundle.procedure_id, doctor:tempBundle.doctor},'bundleRemove')
      setPopupVis(false)
      setTrigger2(true)
    } else {
      setTempBundle([])
      setPopupVis(false)
    }
    console.log(savedData)
  }

  useEffect(() => {
    console.log(doctorData)
  }, [doctorData])
  

  return (
    <Box sx={style.savedMainBox}>
    <Navbar user={facilityData} userAnalyticsid={userAnalyticsid}/>
    <Box sx={style.savedMainBox2}>
      <Box sx={style.bundlesHeaderBox}>
        <Typography variant='h4'>Bundles 
            <Tooltip title={<h2 style={style.toolTipBundles}>Here you can see your Bundled records, click on one to delete them</h2>} arrow>
              <InfoIcon sx={style.infoIconsBundles}><SearchIcon /></InfoIcon>
            </Tooltip>
        </Typography>
    </Box>
      <Stack direction={'row'} sx={style.searchNFilterStack}>
        <Box sx={style.serachFieldBox}>
        <FormControl sx={style.savedFormControl}>
          <InputLabel shrink={false} >{category.length>1 ? '' : 'Select Category'}</InputLabel>
          <Select sx={style.savedFormControlSelect} MenuProps={{ disableScrollLock: true }}  onChange={(e) => setCategory(e.target.value)}>
                 <MenuItem value='all'>All</MenuItem>
                 <MenuItem value='doctor'>Doctor</MenuItem>
                 <MenuItem value='procedure_id'>Procedure</MenuItem>
                 <MenuItem value='base_cost'>Base Cost</MenuItem>
                 <MenuItem value='profit_margin'>Profit</MenuItem>
                 <MenuItem value='total'>Total</MenuItem>
                 <MenuItem value='room_and_board_cost'>Room and Board Cost</MenuItem>
            </Select>
        </FormControl>
          <TextField 
                label={!isFocused ? "Search" : ""}
                type={category === 'profit_margin' ? 'number' : ''}
                sx={style.serachFieldTextfield}
                onChange={(e) => setTermFind(e.target.value)} 
                onFocus={() => setIsFocused(true)} 
                onBlur={() => setIsFocused(false)}// When the input is unfocused
                onKeyDown={handleKeyDown}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip  title={<h2 style={style.toolTip}>Search for a procedure by clicking on the icon</h2>} arrow>
                      <Button sx={style.savedTooltipButton} onClick={() => handleIconClick()}> <SearchIcon sx={{color:'black',  }}/> </Button>
                      </Tooltip>
                    </InputAdornment>
                  )
                }}
              />   
        </Box>
      </Stack>
      { popupVis && 
        <Box sx={{zIndex: 10, position:'absolute', right:'30%',left:'30%', top:'50%', backgroundColor:'white', borderRadius:'20px',p:'20px', border:'4px solid black', width:'auto'}}>
            <Typography sx={{display:'flex', textAlign:'center', alignItems:'center', justifyContent:'center', mb:'10px'}}>
              { facilityData.account_type !== "demo" ? 'Are you sure you want to delete this Bundle?' : 'You cannot delete bundles in the demo'}
            </Typography>
            <Stack direction='row' sx={{mr:'auto', ml:'auto', display:'flex'}}>
            { facilityData.account_type !== "demo" ?
            <>
              <Button sx={{color: 'white',  mr:'10px', borderRadius: '10px', backgroundColor: '#23408e', padding: '15px', width: '100%',transition: 'transform 0.3s, background-color 0.3s',
              '&:hover': {  transform: 'scale(1.05)',backgroundColor: '#1e3a7e' }, pointerEvents: 'auto',}} onClick={() => handleConfirmClick('yes')}>Delete</Button>
              <Button sx={{color: 'white',  borderRadius: '10px', backgroundColor: '#23408e', padding: '15px', width: '100%',transition: 'transform 0.3s, background-color 0.3s',
              '&:hover': {  transform: 'scale(1.05)',backgroundColor: '#1e3a7e' }, pointerEvents: 'auto',}} onClick={() => handleConfirmClick('no')}>Cancel</Button></>
              : <><Button  sx={{ display:'flex', mr:'auto', ml:'auto', color: 'white',  borderRadius: '10px', backgroundColor: '#23408e', padding: '15px', width: '50%',transition: 'transform 0.3s, background-color 0.3s',
                '&:hover': {  transform: 'scale(1.05)',backgroundColor: '#1e3a7e' }, pointerEvents: 'auto',}} onClick={() => handleConfirmClick('no')}>Close</Button></>}
            </Stack>
        
        </Box>
      }
      <Box sx={style.savedListHeader}>
       <Stack direction='row' sx={style.savedListHeaderStack}>
        <Typography variant="h5" sx={style.savedListTypo}>Doctor</Typography>
        <Typography variant="h5" sx={style.savedListTypo}>Procedure Code</Typography>
        <Typography variant="h5" sx={style.savedListTypo}>Base Cost</Typography>
        <Typography variant="h5" sx={style.savedListTypo}>Profit</Typography>
        <Typography variant="h5" sx={style.savedListTypo}>Total</Typography>
        <Typography variant="h5" sx={style.savedListTypo}>RaBC</Typography>
      </Stack>
      {
      savedData.length >= 1 ? (
          filiteredData.length > 0 && typeof filiteredData[0] === 'object' ? (
            <Box sx={style.savedListBodyBox}>    
              {paginatedData.map((save, idx) => (
                <Card key={idx}>
                  <CardActionArea onClick={() => handleBundleClick(paginatedData[idx])}>
                  <Box sx={{ borderRadius: '5px', padding: '3px', marginTop: '5px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', alignItems: 'center', backgroundColor: idx % 2 === 0 ? 'white' : 'lightgray', }}>
                    <Typography variant="body1" sx={style.savedListTypo}>{save.id === facilityData.id && `${save.doctor}`}</Typography>
                    <Typography variant="body1" sx={style.savedListTypo}>{save.id === facilityData.id && `${save.procedure_id}`}</Typography>
                    <Typography variant="body1" sx={style.savedListTypo}>{save.id === facilityData.id && `${parseFloat(save.base_cost).toFixed(0)}`}</Typography>
                    <Typography variant="body1" sx={style.savedListTypo}>{save.id === facilityData.id && `${save.profit_margin}%`}</Typography>
                    <Typography variant="body1" sx={style.savedListTypo}>{save.id === facilityData.id && `${save.total}`}</Typography>
                    <Typography variant="body1" sx={style.savedListTypo}>{save.id === facilityData.id && `${save.room_and_board_cost}`}</Typography>
                  </Box>
                  </CardActionArea>
                </Card>
              ))}
            </Box>
            ) : (
                <Box>
                    <Typography variant="body1">No data available</Typography>
                </Box>
            )
            ) : (
              <Box>
                <Typography variant="body1">No data available</Typography>
              </Box>
            )
          }
    </Box> 
    <Pagination
          sx={style.savedPagination}
        count={pageMax}
        page = {currentPage}
        onChange={(e, value) => setCurrentPage(value)}
      />
    </Box>
  </Box>
  )
}

export default Saved